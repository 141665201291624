<template>
	<div>
		<van-nav-bar
			title="米因智慧健康疫苗服務"
			fixed
			class="qjc-nav-bar" />
		<div class="home">
			<div class="home_head">
				<van-image class="home_banner" :src="require('@/assets/images/vaccines/vaccines_banner.png')"></van-image>
			</div>
			<div class="home_content" :style="{'min-height': height + 'rem'}">
				<div class="vaccines_tips">提示：請選擇對應的服務入口，進行在線登記預約。</div>
				<div class="vaccines_self" @click="handleToScan">
				  <van-image :src="require('@/assets/images/vaccines/home_scan.png')" alt="" class="self_no_icon"></van-image>
				  疫苗卡使用
				</div>
				<div class="banner_buy_cell" @click="handleToGoods">
					<van-image class="banner_buy" :src="require('@/assets/images/vaccines/home_banner_buy.png')"></van-image>
				</div>
				<div class="banner_buy_cell" @click="handleToHpv">
					<van-image class="banner_buy" :src="require('@/assets/images/vaccines/home_hpv.png')"></van-image>
				</div>
				<div class="vaccines_list">
					<div class="vaccines_item" @click="handleToOrder">
						<div class="vaccines_item_icon">
							<van-image :src="require('@/assets/images/vaccines/order_icon.png')" alt="" class="appoint_icon"></van-image>
						</div>
						<div class="vaccines_item_text">訂單記錄</div>
					</div>
					<div class="vaccines_item" @click="handleToAppoint">
						<div class="vaccines_item_icon">
							<van-image :src="require('@/assets/images/vaccines/appoint_icon.png')" alt="" class="appoint_icon"></van-image>
						</div>
					<!-- <view class="vaccines_item_text">登记预约记录</view> -->
						<div class="vaccines_item_text">預約記錄</div>
					</div>
				  
					<div class="vaccines_item" @click="handleToRecord">
						<div class="vaccines_item_icon">
							<van-image :src="require('@/assets/images/vaccines/inoculation_icon.png')" alt="" class="inoculation_icon"></van-image>
						</div>
						<div class="vaccines_item_text">接種記錄</div>
					</div>
				</div>
			</div>
		</div>
		
		
		
	</div>
</template>

<script>
	import { isPay } from '@/js/axios'
	
	import Vue from 'vue'
	import { NavBar, Image, Cell, Button, Tabbar, TabbarItem, Toast, Popup, Dialog } from 'vant'
	Vue.use(NavBar)
	   .use(Image)
	   .use(Cell)
	   .use(Button)
	   .use(Tabbar)
	   .use(TabbarItem)
	   .use(Toast)
	   .use(Popup)
	   .use(Dialog);
	
	export default{
		name: 'vaccines',
		data (){
			return {
				height: 0
			}
		},
		computed: {
			
		},
		mounted() {
			let clientWidth = document.documentElement.clientWidth;
			let clientHeight = document.documentElement.clientHeight;
			let ratio = clientWidth / 375;
			let list_height = clientHeight - (3.26 * 100 * ratio / 2);
			this.height = (list_height/100)/ratio * 2; //历史记录 高度
			
			document.querySelector('body').setAttribute('style', 'background-color:#F5F7FA')
		},
		beforeDestroy() {
			document.querySelector('body').removeAttribute('style')
		},
		methods: {
			handleToScan() {
				this.$router.push('/vaccines/card')
			},
			handleToGoods() {
				this.$router.push('/vaccines/goods_vac')
			},
			handleToHpv() {
				this.$router.push('/vaccines/goods_hpv')
			},
			handleToOrder() {
				this.$router.push('/vaccines/order')
			},
			handleToAppoint() {
				this.$router.push('/vaccines/appoint')
			},
			handleToRecord() {
				this.$router.push('/vaccines/record')
			},
		}
	}
</script>

<style lang="scss" scoped>
	.home{
		margin-top: 0.88rem;
		background: #fff;
	}
	.home_head {
		width: 100%;
		height: 2.72rem;
	}
	.home_banner {
		width: 100%;
		height: 2.72rem;
	}
	.home_content {
		position: relative;
		width: 100%;
		padding: 0.42rem 0.30rem;
		border-radius: 0.32rem 0.32rem 0 0;
		background-color: #fff;
		margin-top: -0.34rem;
		z-index: 9;
	}
	.vaccines_tips {
		font-size: 0.28rem;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #32363E;
		line-height: 0.32rem;
		text-align: left;
	}
	.vaccines_self {
		display: flex;
		align-items: center;
		width: 100%;
		height: 1.20rem;
		font-size: 0.34rem;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		text-align: left;
		background: linear-gradient(90deg, #FEAA37 0%, #FDCA3F 100%);
		border-radius: 0.16rem;
		padding: 0 0.40rem;
		line-height: 1.20rem;
		margin-top: 0.36rem;
	}
	.vaccines_self_no {
		display: flex;
		align-items: center;
		width: 100%;
		height: 1.20rem;
		font-size: 0.34rem;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
		text-align: left;
		background: linear-gradient(90deg, #2EBBBC 0%, #62D9C5 100%);
		border-radius: 0.16rem;
		padding: 0 0.40rem;
		line-height: 1.20rem;
		margin-top: 0.36rem;
	}
	.vaccines_item_icon {
		height: 0.66rem;
		text-align: center;
	}
	
	.self_icon {
		width: 0.52rem;
		height: 0.52rem;
		margin-right: 0.26rem;
	}
	.self_no_icon {
		width: 0.48rem;
		height: 0.48rem;
		margin-right: 0.26rem;
	}
	.appoint_icon {
		width: 0.54rem;
		height: 0.66rem;
	}
	.qr_code {
		width: 0.68rem;
		height: 0.58rem;
	}
	.inoculation_icon {
		width: 0.66rem;
		height: 0.66rem;
	}
	.banner_buy_cell {
		width: 100%;
		height: 2.64rem;
		margin-top: 0.32rem;
	}
	.banner_buy {
		width: 100%;
		height: 2.64rem;
	}
	.vaccines_list {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding: 0 0.30rem;
		margin-top: 0.80rem;
	}
	.vaccines_item_text {
		font-size: 0.28rem;
		font-family: PingFang SC-Medium, PingFang SC;
		font-weight: 500;
		color: #32363E;
		line-height: 0.32rem;
		margin-top: 0.20rem;
	}

</style>
